import React, { useState } from "react";

import logo from "../../assets/logo.png";
import "./FindTest.css";

import APICall from "../../services/api.service";
import { Link } from "react-router-dom";

function FindTest() {
  const [testId, setTestId] = useState();
  const handleChange = (event) => {
    const value = event.target.value; // get the value from the input field
    setTestId(value.trim());
  };
  return (
    <div className="user-test-1-container">
      <div
        className="user-test-1-content"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="content">
          <div
            className="logo"
            style={{ display: "flex", justifyContent: "center", width: "auto" }}
          >
            <img src={logo} alt="" />
          </div>
          <div className="user-test-1-body">
            <div className="body-left">
              <span>Who we are?</span>
              <p>
                Welcome to Excellio, Our advanced technology based recruiting
                platform offers a streamlined and personalized experience for
                corporate teams, With an intuitive dashboard tailored to
                different job roles,effortlessly manage all your hiring
                operations. Form shortlisting to assessments and interviews,
                Excellio recruiter optimizes your hiring process from start to
                finish.
              </p>
            </div>
            <div className="body-right">
              <div className="question">
                <span>Contact us</span>
                <div
                  className="buttons"
                  style={{
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  <div className="action-button">
                    <a
                      href="mailTo:info@excellio.ai"
                      style={{ textDecoration: "none" }}
                    >
                      <span style={{ color: "white" }}>Contact Now</span>
                    </a>
                  </div>
                  <div className="action-button">
                    <a
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      href="https://www.excellio.ai"
                    >
                      <span style={{ color: "white" }}>Visite Site</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="footer">
        <img src={logo} alt="logo" />
      </div> */}
    </div>
  );
}

export default FindTest;
