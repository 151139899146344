import { useContext, useState, useRef, useEffect } from "react";
import TestContext from "./testContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import APICall from "../../services/api.service";
import CameraContext from "../camera/CameraContext";

const TestProvider = ({ children }) => {
  const navigate = useNavigate();
  const { stopCamera } = useContext(CameraContext);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [tabSwitchTiming, setTabSwitchTiming] = useState(0);
  const [fullSceenExiteCount, setFullScreenExiteCount] = useState(0);
  const [testData, setTestData] = useState();
  const [testSummary, setTestSummary] = useState();
  const [testSummary2, setTestSummary2] = useState();
  const [testQuestions, setTestQuestions] = useState();
  const [remainingTime, setRemainingTime] = useState();
  const [userData, setUserData] = useState({});
  const [inviteId, setInviteId] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const timerRef = useRef(null);
  const [attemptedCount, setAttemptedCount] = useState(0);

  const handleTestSubmit = async (invId) => {
    console.log(invId);
    const timeSpentInSeconds = getTotalTimeSpentInSeconds(
      testData.totalTiming,
      remainingTime
    );
    try {
      const submitData = {
        inviteId: invId,
        candidate_total_time_spent: timeSpentInSeconds,
        candidate_full_screen_exit_count: fullSceenExiteCount,
        candidate_tab_switch_count: tabSwitchCount,
        testEndTime: new Date(),
      };

      const response = await APICall(
        `/api/exam/test/${testData._id}/submit`,
        submitData,
        2
      );
      if (response.data) {
        setIsSubmitted(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      navigate("/testsubmitted", { replace: true });
      stopCamera();
    }
  }, [isSubmitted]);

  const getTotalTimeSpentInSeconds = (
    totalTimeInMinutes,
    remainingTimeString
  ) => {
    const remainingTime = moment.duration(remainingTimeString);
    const totalSeconds = totalTimeInMinutes * 60; // convert total minutes to seconds
    const remainingSeconds = remainingTime.asSeconds();
    const totalTimeSpentInSeconds = totalSeconds - remainingSeconds;
    return totalTimeSpentInSeconds;
  };

  const startTimer = (invId) => {
    if (testSummary?.remainingTime * 60 * 1000) {
      setRemainingTime(testSummary.remainingTime);
    } else setRemainingTime(testData.totalTiming * 60 * 1000);

    let intervalRef = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timerRef.current);
          if (!isSubmitted) {
            handleTestSubmit(invId);
          }
          return 0;
        } else if (prevTime % 5000 === 0) {
          // performAction();
          // console.log("reamining Time:" + prevTime);
        }
        return prevTime - 1000;
      });
    }, 1000);

    timerRef.current = intervalRef;
  };

  const contextValue = {
    testData,
    setTestData,
    testSummary,
    setTestSummary,
    testSummary2,
    setTestSummary2,
    testQuestions,
    setTestQuestions,
    handleTestSubmit,
    startTimer,
    remainingTime,
    tabSwitchCount,
    setTabSwitchCount,
    setFullScreenExiteCount,
    fullSceenExiteCount,
    inviteId,
    setInviteId,
    setTabSwitchTiming,
    tabSwitchTiming,
    userData,
    setUserData,
    attemptedCount,
    setAttemptedCount,
  };

  return (
    <TestContext.Provider value={contextValue}>{children}</TestContext.Provider>
  );
};

export default TestProvider;
