import React, { useState } from "react";
import "./UserTest4Sidebar.css";

function UserTest4Sidebar(props) {
  const [displayQuestionDetails, setDisplayQuestionDetails] = useState(false);
  const {
    currentQuestionIndex,
    handleQuestionChange,
    currentPage,
    totalPages,
    testQuestions,
    handlePageChange,
    indexOfLastQuestion,
    indexOfFirstQuestion,
    openQuestion,
    overview,
  } = props;

  return (
    <div className="test-4-popup-container">
      <div className="test-4-popup">
        <div className="test-4-popup-left-container">
          <>
            {!displayQuestionDetails && (
              <div
                className="back"
                onClick={() => {
                  setDisplayQuestionDetails(true);
                }}
              >
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.3081 22.1206C19.064 22.3646 19.064 22.7604 19.3081 23.0044C19.5521 23.2485 19.9479 23.2485 20.1919 23.0044L23.8125 19.3839C24.3007 18.8957 24.3007 18.1043 23.8125 17.6161L20.1919 13.9956C19.9479 13.7515 19.5521 13.7515 19.3081 13.9956C19.064 14.2396 19.064 14.6354 19.3081 14.8794L22.3036 17.875H13.5C13.1548 17.875 12.875 18.1548 12.875 18.5C12.875 18.8452 13.1548 19.125 13.5 19.125H22.3036L19.3081 22.1206Z"
                    fill="white"
                  />
                  <circle cx="18.5" cy="18.5" r="18" stroke="white" />
                </svg>
              </div>
            )}

            {testQuestions
              ?.slice(indexOfFirstQuestion, indexOfLastQuestion)
              .map((question, index) => {
                const questionIndex = indexOfFirstQuestion + index;
                const isActive = questionIndex === currentQuestionIndex;
                const isAttempted = question.isAttempted;

                return (
                  <div className="ques" key={questionIndex}>
                    <span
                      className={`${isAttempted ? "attempted" : ""} ${
                        isActive ? "selected" : "not-selected"
                      }`}
                      onClick={() =>
                        overview
                          ? openQuestion(question._id)
                          : handleQuestionChange(question._id, questionIndex)
                      }
                    >
                      {questionIndex + 1}
                    </span>
                    {displayQuestionDetails && (
                      <p>
                        {question.question.split(" ").slice(0, 10).join(" ")}
                        {question.question.split(" ").length > 10 ? "..." : ""}
                      </p>
                    )}
                  </div>
                );
              })}

            <div className="pagination">
              {currentPage !== 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.88 19.06L10.82 12.5L15.88 5.93999"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}

              {currentPage !== totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.12 19.06L13.18 12.5L8.12 5.93999"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          </>
        </div>

        {displayQuestionDetails && (
          <div
            className="test-4-popup-right-container"
            onClick={() => setDisplayQuestionDetails(false)}
          >
            <svg
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
              <path
                d="M17.6919 14.8794C17.936 14.6354 17.936 14.2396 17.6919 13.9956C17.4479 13.7515 17.0521 13.7515 16.8081 13.9956L13.1875 17.6161C12.6993 18.1043 12.6993 18.8957 13.1875 19.3839L16.8081 23.0044C17.0521 23.2485 17.4479 23.2485 17.6919 23.0044C17.936 22.7604 17.936 22.3646 17.6919 22.1206L14.6964 19.125H23.5C23.8452 19.125 24.125 18.8452 24.125 18.5C24.125 18.1548 23.8452 17.875 23.5 17.875H14.6964L17.6919 14.8794Z"
                fill="#333333"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserTest4Sidebar;
