import React, { useState, useEffect, useContext } from "react";
import Navbar2 from "../navbar-2/Navbar2";
import TestLoading from "../test-loading/TestLoading";

import "./TestReview.css";
import logo from "../../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

import TestEndWarning from "../test-end-warning/TestEndWarning";
import TestContext from "../../context/test/testContext";
import CameraContext from "../../context/camera/CameraContext";
import useVisibilityChange from "../../utils/visibility";
import ErrorPopup from "../ErrorPopup/common/ErrorPopup";
import queryString from "query-string";

function TestReview() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [endTask, setEndTask] = useState(false);
  const [isCameraRequired, setIsCameraRequired] = useState(true);

  const search = location.search;
  const parsed = queryString.parse(search);
  const currentQuestion = parsed.currentQuestion;

  const {
    testData,
    inviteId,
    handleTestSubmit,
    testQuestions,
    setTabSwitchCount,
    tabSwitchCount,
    attemptedCount,
  } = useContext(TestContext);
  const {
    isCameraOn,
    videoRef,
    checkIfCameraExists,
    checkCameraStatus,
    startSnapshotInterval,
    takeScreenSnapshot,
  } = useContext(CameraContext);

  useVisibilityChange(() => {
    if (document.visibilityState !== "visible") {
      setTabSwitchCount((prevCount) => prevCount + 1);
      takeScreenSnapshot();
      testData.proctoringSettings.map((setting) => {
        if (setting.setting === "Logout on leaving a test interface") {
          if (setting.active) {
            handleTestSubmit(inviteId);
          }
        }
      });
    }
  });
  const startCamera = () => {
    if (checkIfCameraExists()) {
      checkCameraStatus();
    } else {
      return "Camera not found";
    }
  };

  const calculateStatistics = () => {
    // Calculate statistics for each topic
    const topics = {};
    testQuestions.forEach((question) => {
      const { type, isAttempted } = question;
      if (!topics[type]) {
        topics[type] = {
          total: 0,
          attempted: 0,
          pending: 0,
        };
      }
      topics[type].total++;
      if (isAttempted) {
        topics[type].attempted++;
      } else {
        topics[type].pending++;
      }
    });
    return topics;
  };

  const topicsStatistics = calculateStatistics();

  const startSnapShots = () => {
    testData.proctoringSettings.map((setting) => {
      if (setting.setting === "Take Snapshots via webcam every 30 second") {
        if (setting.active) {
          startSnapshotInterval();
        }
      }
    });
  };

  useEffect(() => {
    testData.proctoringSettings.map((setting) => {
      if (setting.setting === "Take Snapshots via webcam every 30 second") {
        if (setting.active) {
          startCamera();
          startSnapShots();
        } else {
          setIsCameraRequired(false);
        }
      }
    });
  }, [isCameraOn]);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  return (
    <div className="user-test-review-container">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        style={{ position: "fixed", bottom: 20, right: 20, width: "100px" }}
      />
      {isLoading ? (
        <div className="loader">
          <TestLoading />
        </div>
      ) : (
        <div>
          <Navbar2
            endtask={() => {
              setEndTask(!endTask);
            }}
            currentQuestion={currentQuestion}
            hideEndBtn
          />

          <div className="user-test-4-content">
            {endTask && (
              <TestEndWarning
                endtask={() => {
                  setEndTask(!endTask);
                }}
              />
            )}

            <div className="main-content">
              <div className="review-container">
                <div className="header">
                  <div className="review-title">
                    <div className="group">
                      <span>Review your test details before submitting</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="review-container">
                  <table className="review-table">
                    <tbody>
                      <tr>
                        <th className="reviewTableTh">Section</th>
                        <th className="reviewTableTh">Total</th>
                        <th className="reviewTableTh">Attempted</th>
                        <th className="reviewTableTh">Pending</th>
                      </tr>
                      {Object.entries(topicsStatistics).map(
                        ([topic, statistics]) => (
                          <tr key={topic} className="reviewTableTr">
                            <td className="reviewTableTd text-bold">{topic}</td>
                            <td className="reviewTableTd">
                              {statistics.total}
                            </td>
                            <td className="reviewTableTd">
                              {statistics.attempted}
                            </td>
                            <td className="reviewTableTd">
                              {statistics.pending}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="note">
                  <p>
                    *Do check whether you have attempted all your qestoins. Make
                    sure to double check before submitting. Once submitted you
                    can't go back. After submitting test will end.
                  </p>
                </div>
                <div className="btn-container">
                  <div
                    className="back-button"
                    onClick={() => {
                      console.log(currentQuestion);

                      currentQuestion === "undefined"
                        ? navigate(-1)
                        : navigate(`/test/${currentQuestion}`);
                    }}
                  >
                    <svg
                      width="24"
                      height="21"
                      viewBox="0 0 24 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="10.5" r="10" fill="#827C7C" />
                      <path
                        d="M15 5.45926L9 10.1123L15 14.7654"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Back</span>
                  </div>

                  <div
                    className="next-button"
                    onClick={() => setEndTask(!endTask)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 200 200"
                    >
                      <circle cx="100" cy="100" r="80" fill="white" />
                      <path
                        d="M60 110 L90 140 L190 50"
                        fill="none"
                        stroke="#00c49a"
                        strokeWidth="15"
                      />
                    </svg>
                    <span>Submit Test</span>
                  </div>
                </div>
              </div>
              <div className="review-footer">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      )}
      {isCameraRequired && !isCameraOn && (
        <ErrorPopup
          errorData={{
            errorMessage: "Camera got turned off!",
            actions: [
              {
                button: "Start Camera",
                action: startCamera,
              },
            ],
          }}
        ></ErrorPopup>
      )}
    </div>
  );
}

export default TestReview;
