import React, { useState, useEffect, useContext } from "react";
import Navbar2 from "../navbar-2/Navbar2";
import TestLoading from "../test-loading/TestLoading";
import "./TestOverview.css";
import logo from "../../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserTest4Sidebar from "../user-test-4-sidebar/UserTest4Sidebar";
import TestEndWarning from "../test-end-warning/TestEndWarning";
import TestContext from "../../context/test/testContext";
import CameraContext from "../../context/camera/CameraContext";
import useVisibilityChange from "../../utils/visibility";
import ErrorPopup from "../ErrorPopup/common/ErrorPopup";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { enterFullScreen } from "../../utils/screenUtils";
import queryString from "query-string";
import { Grid, Stack } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function TestOverview() {
  const [isLoading, setIsLoading] = useState(true);
  const [expandedType, setExpandedType] = useState("");
  const [expandedSkill, setExpandedSkill] = useState("");
  const [typeWiseSkillQuestions, setTypeWiseSkillQuestions] = useState([]); // [{type, skillWiseQuestions: [{skill, questions}]}
  const location = useLocation();
  const [endTask, setEndTask] = useState(false);
  // const [testSummary, setTestSummary] = useState();
  const navigate = useNavigate();
  const {
    testQuestions,
    testData,
    setTabSwitchCount,
    handleTestSubmit,
    inviteId,
    testSummary2,
  } = useContext(TestContext);

  const {
    isCameraOn,
    videoRef,
    checkIfCameraExists,
    checkCameraStatus,
    startSnapshotInterval,
    takeScreenSnapshot,
  } = useContext(CameraContext);

  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;

  const search = location.search;
  const parsed = queryString.parse(search);
  const currentQuestion = parsed.currentQuestion;

  const [isCameraRequired, setIsCameraRequired] = useState(false);

  const startCamera = () => {
    if (checkIfCameraExists()) {
      checkCameraStatus();
    } else {
      return "Camera not found";
    }
  };

  useVisibilityChange(() => {
    if (document.visibilityState !== "visible") {
      setTabSwitchCount((prevCount) => prevCount + 1);
      takeScreenSnapshot();
      testData?.proctoringSettings?.map((setting) => {
        if (setting.setting === "Logout on leaving a test interface") {
          if (setting.active) {
            handleTestSubmit(inviteId);
          }
        }
      });
    }
  });

  // Handle click on question
  const openQuestion = async (questionId) => {
    navigate(`/test/${questionId}`);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startSnapShots = () => {
    testData.proctoringSettings.map((setting) => {
      if (setting.setting === "Take Snapshots via webcam every 30 second") {
        if (setting.active) {
          startSnapshotInterval();
        }
      }
    });
  };

  useEffect(() => {
    testData?.proctoringSettings?.map((setting) => {
      if (setting.setting === "Take Snapshots via webcam every 30 second") {
        if (setting.active) {
          startCamera();
          startSnapShots();
          setIsCameraRequired(true);
        }
      }
    });
  }, [isCameraOn]);

  const handleTypeChange = (item) => (event, newExpanded) => {
    setExpandedType(newExpanded ? item : false);
    console.log(item);
  };
  const handleSkillChange = (item) => (event, newExpanded) => {
    setExpandedSkill(newExpanded ? item : false);
    console.log(item);
  };

  //calculate total pages
  const totalPages = Math.ceil(testQuestions?.length / questionsPerPage);

  //find sections
  const uniqueType = [
    ...new Set(testQuestions?.map((question) => question.type)),
  ];

  const tempSkills = [...new Set(testData?.skill?.map((skill) => skill._id))];

  const skills = [];

  tempSkills.map((skill) => {
    const skillData = testData?.skill?.find((item) => item._id === skill);
    skills.push(skillData);
  });

  const filterQuestions = () => {
    const typeWiseSkillQuestions = [];

    // Iterate over each unique type
    uniqueType.forEach((type) => {
      // Filter questions of the current type

      const skillWiseQuestions = testSummary2?.skills.flatMap((skill) => {
        let questions = [];

        if (!skill.isRandom) {
          // Get questions of the current skill and type
          questions = [
            ...skill.easy_question,
            ...skill.hard_question,
            ...skill.medium_question,
          ].filter((question) => question.type === type);
        } else {
          // Handle random skills
          testSummary2?.randomSkills?.forEach((item) => {
            if (skill?.skill_id?._id === item?.skillId?._id) {
              questions.push(
                ...item.question.filter((question) => question.type === type)
              );
            }
          });
        }

        if (questions.length === 0) return [];

        // Return the skill and its questions
        return [{ skill: skill.skill_id, questions }];
      });

      // If there are no skill-wise questions of the current type, skip this iteration
      if (skillWiseQuestions.length === 0) return;

      // Calculate total questions for the current type
      const totalQuestions = skillWiseQuestions.reduce(
        (sum, skillQuestion) => sum + skillQuestion?.questions.length,
        0
      );

      // Add the type-wise skill questions to the result
      typeWiseSkillQuestions.push({
        type,
        skillWiseQuestions,
        totalQuestions, // Added totalQuestions key
      });
    });

    setTypeWiseSkillQuestions(typeWiseSkillQuestions);
    console.log(typeWiseSkillQuestions);
  };

  useEffect(() => {
    filterQuestions();
    testData?.proctoringSettings.map((setting) => {
      if (setting.setting === "Turn on fullscreen while test") {
        if (setting.active) {
          enterFullScreen();
        }
      }
    });
  }, []);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return (
    <div className="user-test-overview-container">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        style={{ position: "fixed", bottom: 20, right: 20, width: "100px" }}
      />
      {isLoading ? (
        <div className="loader">
          <TestLoading />
        </div>
      ) : (
        <div>
          <Navbar2
            endtask={() => {
              setEndTask(!endTask);
            }}
            hideOverview
          />

          <div className="user-test-4-content">
            {endTask && (
              <TestEndWarning
                endtask={() => {
                  setEndTask(!endTask);
                }}
              />
            )}
            {
              <UserTest4Sidebar
                // currentQuestionIndex={currentQuestionIndex}
                testQuestions={testQuestions}
                // handleQuestionChange={handleQuestionChange}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfFirstQuestion={indexOfFirstQuestion}
                indexOfLastQuestion={indexOfLastQuestion}
                overview
                openQuestion={openQuestion}
              />
            }

            <div className="right-side-content">
              <div className="right-container">
                <div className="header">
                  <div className="title">
                    <div className="group">
                      <span>Test Overview ({testQuestions?.length})</span>
                    </div>
                    <div className="score-container">
                      <span>Total Score: {testData?.totalScore}</span>
                      <span className="score-badge">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0226 19.3959L7.21012 16.6667H3.33512V12.7917L0.543457 10.0001L3.33512 7.20841V3.33341H7.21012L10.0226 0.541748L12.7935 3.33341H16.6685V7.20841L19.4601 10.0001L16.6685 12.7917V16.6667H12.7935L10.0226 19.3959ZM10.0226 17.6459L12.2709 15.4167H15.4185V12.2709L17.6893 10.0001L15.4185 7.72925V4.58341H12.2726L10.0226 2.31258L7.73096 4.58341H4.58512V7.72925L2.31429 10.0001L4.58512 12.2709V15.4167H7.71012L10.0226 17.6459ZM7.56429 13.5417L10.0018 12.0626L12.4393 13.5417L11.7935 10.7709L13.9601 8.89591L11.106 8.66675L10.0018 6.04175L8.89762 8.66675L6.04346 8.89591L8.21012 10.7709L7.56429 13.5417Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  {
                    <Link
                      style={{ textDecoration: "none" }}
                      to={
                        currentQuestion ? `/test/${currentQuestion}` : "/test"
                      }
                    >
                      <div className="btn-next">
                        <span>{currentQuestion ? "Back" : "Next"}</span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="10" r="10" fill="white" />
                          <path
                            d="M8 15.2661L14 10.613L8 5.95996"
                            stroke="#00C49A"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  }
                </div>
                <div className="overview-container">
                  {typeWiseSkillQuestions?.map((typeQuestion) => {
                    const sectionMarks = typeQuestion.skillWiseQuestions.reduce(
                      (sum, skillQuestion) =>
                        sum +
                        skillQuestion.questions.reduce(
                          (sum, question) => sum + question.score,
                          0
                        ),
                      0
                    );

                    return (
                      <Accordion defaultExpanded={true} key={typeQuestion.type}>
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{ backgroundColor: "#FEE9E1" }}
                          expandIcon={
                            <ArrowForwardIosSharpIcon
                              sx={{ fontSize: "0.9rem", color: "black" }}
                            />
                          }
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="button">
                                {`${typeQuestion.type} (${typeQuestion.totalQuestions})`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                Section Marks {sectionMarks}
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack spacing={2}>
                            {typeQuestion.skillWiseQuestions.map(
                              (skillQuestion) => {
                                const skillMarks =
                                  skillQuestion.questions.reduce(
                                    (sum, question) => sum + question.score,
                                    0
                                  );

                                return (
                                  <Accordion
                                    key={skillQuestion.skill}
                                    sx={{ border: "none" }}
                                  >
                                    <AccordionSummary
                                      aria-controls="panel1d-content"
                                      id="panel1d-header"
                                      sx={{ backgroundColor: "#76b6a9" }}
                                      expandIcon={
                                        <ArrowForwardIosSharpIcon
                                          sx={{
                                            fontSize: "0.9rem",
                                            color: "#fff",
                                          }}
                                        />
                                      }
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Typography
                                            variant="button"
                                            color={"#fff"}
                                          >
                                            {`${skillQuestion.skill.skills} (${skillQuestion.questions.length})`}
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography color={"#fff"}>
                                            Section Marks {skillMarks}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {skillQuestion.questions.map(
                                        (question, index) => (
                                          <div
                                            className="question"
                                            key={question._id}
                                            onClick={() =>
                                              openQuestion(question._id)
                                            }
                                          >
                                            <div className="question-title">
                                              <div>{`Q.${index + 1} `}</div>
                                              {question?.html ? (
                                                <div
                                                  className="sun-editor-editable"
                                                  dangerouslySetInnerHTML={{
                                                    __html: question?.html,
                                                  }}
                                                />
                                              ) : (
                                                <div>{question?.question}</div>
                                              )}
                                            </div>

                                            <div className="question-status">
                                              <div className="marks">
                                                {question.score}
                                              </div>
                                              <div className="attempt-status">
                                                {testQuestions.find(
                                                  (q) => q._id === question._id
                                                ).isAttempted ? (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 200 200"
                                                  >
                                                    <circle
                                                      cx="100"
                                                      cy="100"
                                                      r="80"
                                                      fill="none"
                                                      stroke="#00C49A"
                                                      strokeWidth="20 "
                                                    />
                                                    <path
                                                      d="M60 110 L90 140 L190 50"
                                                      fill="none"
                                                      stroke="#00C49A"
                                                      strokeWidth="15"
                                                    />
                                                  </svg>
                                                ) : (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 200 200"
                                                  >
                                                    <circle
                                                      cx="100"
                                                      cy="100"
                                                      r="80"
                                                      fill="none"
                                                      stroke="#949393"
                                                      strokeWidth="15"
                                                    />
                                                    <path
                                                      d="M60 110 L90 140 L190 50"
                                                      fill="none"
                                                      stroke="#949393"
                                                      strokeWidth="15"
                                                    />
                                                  </svg>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              }
                            )}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </div>
                <div className="footer">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isCameraRequired && !isCameraOn && (
        <ErrorPopup
          errorData={{
            errorMessage: "Camera got turned off!",
            actions: [
              {
                button: "Start Camera",
                action: startCamera,
              },
            ],
          }}
        ></ErrorPopup>
      )}
    </div>
  );
}

export default TestOverview;
