import React, {
  useState,
  useRef,
  useContext,
  useCallback,
  useEffect,
} from "react";
import "./EnterTest.css";
import Navbar from "../navbar/Navbar";
import UserTest2Popup from "../user-test-2-popup/UserTest2Popup";
import person from "../../assets/person.png";
import TestContext from "../../context/test/testContext";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";
import CameraContext from "../../context/camera/CameraContext";
import logo from "../../assets/logo.png";

import ErrorPopup from "../ErrorPopup/common/ErrorPopup";
import APICall from "../../services/api.service";
import TestLoading from "../test-loading/TestLoading";
import Webcam from "react-webcam";
import queryString from "query-string";

function EnterTestPreview() {
  const [toggleHelp, settoggleHelp] = useState(false);

  const [agreeToTermsAndConditions, setAgreeToTermsToConditions] =
    useState(false);

  const [isError, setError] = useState(false);

  const [errorData, setErrorData] = useState();

  const {
    testData,
    startTimer,
    setTestQuestions,
    setInviteId,
    userData,
    setTestSummary,
    setTestData,
    setUserData,
  } = useContext(TestContext);
  const { setTestId, setInviteId2, setIsCameraOn, streamRef } =
    useContext(CameraContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const webcamRef = useRef(null);
  const [isWebcamOn, setIsWebcamOn] = useState(false);

  const location = useLocation();
  const search = location.search;
  const parsed = queryString.parse(search);
  const testId = parsed.testId;
  // const candidateData = JSON.parse(parsed.candidateData);
  const [isCameraRequired, setIsCameraRequired] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream;
      setIsWebcamOn(true);
      setIsCameraOn(true);
    } catch (error) {
      setErrorData({
        errorMessage:
          "Camera permissions not granted. Kindly check your browser settings and check your antivirus settings and restart the test.",
        help: "https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop",
      });
      setError(true);
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    setIsWebcamOn(false);
    setIsCameraOn(false);
  };

  const handleOnUserMedia = () => {
    webcamRef.current.stream.addEventListener("inactive", (target) => {
      console.log("Webcam was stopped");
      console.log({ target });
      setIsWebcamOn(false);
      setIsCameraOn(false);
    });
  };

  const handleUserMediaError = useCallback((error) => {
    if (error.name === "NotAllowedError") {
      setIsCameraOn(false);
      setIsWebcamOn(false);
      setErrorData({ errorMessage: "Camera access denied by the user" });
      setError(true);
    } else if (error.name === "NotFoundError") {
      setIsCameraOn(false);
      setIsWebcamOn(false);
      setErrorData({ errorMessage: "No camera found on the device" });
      setError(true);
    } else {
      setIsCameraOn(false);
      setIsWebcamOn(false);
      setErrorData({ errorMessage: "Error accessing camera:" });
      setError(true);
    }
  }, []);

  const getTestQuestions = async () => {
    const questionsList = [];
    const randomSkills = [];
    try {
      const response = await APICall(
        `/api/exam/test/${testData._id}/Combinequestions`
      );
      response.data.testSummaryData.skills.map((skill) => {
        if (!skill.isRandom) {
          const tempQuestions = [
            ...skill.easy_question,
            ...skill.hard_question,
            ...skill.medium_question,
          ];
          tempQuestions.map((q) => questionsList.push(q));
        } else {
          const scores = {};
          skill.score[0].split(",").forEach((item) => {
            const [key, value] = item.split("(");
            scores[key.toLowerCase()] = parseInt(value);
          });

          const temp = {
            skillId: skill.skill_id,
            scores: scores,
          };
          randomSkills.push(temp);
        }
      });

      console.log("random Skills", randomSkills);

      response.data.random.map((skill) => {
        let tempQuestions = [];

        randomSkills.forEach((rSkill) => {
          if (skill.skillId._id === rSkill.skillId._id) {
            tempQuestions = [
              ...skill.question.map((q) => ({
                ...q,
                score: rSkill.scores[q.difficultyLevelId.level],
              })),
            ];
          } else {
          }
        });

        const mappedTempQuestions = tempQuestions.map((q) => ({
          ...q,
          isRandom: true,
        }));

        questionsList.push(...mappedTempQuestions);
      });

      testData.proctoringSettings.map((setting) => {
        if (setting.setting === "Shuffle Questions for each candidate") {
          if (setting.active) {
            for (let i = questionsList.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [questionsList[i], questionsList[j]] = [
                questionsList[j],
                questionsList[i],
              ];
            }
          }
        }
      });
      setTestQuestions(questionsList);
    } catch (error) {
      console.log(error);
    }
  };

  const startTestHandler = async () => {
    if (
      isCameraRequired
        ? isWebcamOn && agreeToTermsAndConditions
        : agreeToTermsAndConditions
    ) {
      try {
        setIsLoading(true);
        setTestId(testData._id);
        getTestQuestions();
        navigate("/preview/testoverview", { replace: true });
        startTimer();
        localStorage.removeItem("testData");
      } catch (error) {
        setErrorData({
          errorMessage: error.response.data.error,
        });
        setIsLoading(false);
        setError(!isError);
      }
    }
  };

  const catchError = () => {
    console.log(isCameraRequired, isWebcamOn, agreeToTermsAndConditions);
    if (isCameraRequired) {
      if (!isWebcamOn && !agreeToTermsAndConditions) {
        setErrorData({
          errorMessage:
            "Can't start test, Camera is off and agree the terms and conditions!",
        });
        setError(true);
      } else {
        if (!isWebcamOn) {
          setErrorData({
            errorMessage: "Can't start test, Please turn on camera",
          });
          setError(true);
        }
        if (!agreeToTermsAndConditions) {
          setErrorData({
            errorMessage:
              "Can't start test, Agree to the terms and conditions!",
            actions: [
              {
                button: "Agree Terms & Conditions",
                action: () => {
                  setAgreeToTermsToConditions(true);
                  setError(false);
                },
              },
            ],
          });
          setError(true);
        }
      }
    } else {
      if (!agreeToTermsAndConditions) {
        setErrorData({
          errorMessage: "Can't start test, Agree to the terms and conditions!",
          actions: [
            {
              button: "Agree Terms & Conditions",
              action: () => {
                setAgreeToTermsToConditions(true);
                setError(false);
              },
            },
          ],
        });
        setError(true);
      }
    }
  };

  const getTestData = async (testID) => {
    try {
      const response = await APICall(`/api/exam/test/${testID}`);

      if (response.data) {
        response.data.data.proctoringSettings.map((setting) => {
          if (setting.setting === "Take Snapshots via webcam every 30 second") {
            if (setting.active) {
              setIsCameraRequired(true);
            }
          }
        });
        setTestData(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getTestSummary = async (testId) => {
    try {
      const response = await APICall(`/api/exam/test/${testId}/summary`);
      if (response.data) {
        setTestSummary(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTestData(testId);
    getTestSummary(testId);
  }, []);

  const toEnableButton = () => {
    if (isCameraRequired) {
      if (agreeToTermsAndConditions && isWebcamOn) {
        return true;
      }
    } else {
      if (agreeToTermsAndConditions) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <TestLoading />
        </div>
      ) : testData ? (
        <div className="user-test-3-container">
          <Navbar
            settoggleHelp={() => settoggleHelp(!toggleHelp)}
            active={"test3"}
          />
          <div className="user-test-3">
            {toggleHelp && (
              <UserTest2Popup
                settoggleHelp={() => settoggleHelp(!toggleHelp)}
              />
            )}
            <div className="left-content">
              <div className="left-body">
                {
                  <div className="left-card">
                    <div className="image-container">
                      {isWebcamOn ? (
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          mirrored={true}
                          onUserMedia={handleOnUserMedia}
                          style={{ width: "100%" }}
                          onUserMediaError={handleUserMediaError}
                        />
                      ) : (
                        <img src={person} alt="" />
                      )}
                    </div>
                    <div className="card-right">
                      <span>Why Camera?</span>
                      <p>
                        This test requires webcam access for proctoring.
                        snapshot will be taken in every 30 secs.
                      </p>
                      {
                        <div className="camera-btn">
                          <button
                            onClick={!isWebcamOn ? startCamera : stopCamera}
                            style={{
                              backgroundColor: !isCameraRequired
                                ? "#ffc09b"
                                : !isWebcamOn && "#FF6812",
                              cursor: !isCameraRequired
                                ? "not-allowed"
                                : "pointer",
                            }}
                            disabled={!isCameraRequired}
                          >
                            {!isWebcamOn ? (
                              <svg
                                width="30px"
                                height="30px"
                                viewBox="0 0 24.00 24.00"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    stroke="#ffffff"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.44"
                                    d="M3 5L17 19M9 7H15C15.5523 7 16 7.44772 16 8V13.5C16 13.8148 16.1482 14.1111 16.4 14.3L20 17V7L16 10M15 17H5C4.44772 17 4 16.5523 4 16V8C4 7.44772 4.44772 7 5 7"
                                  ></path>{" "}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                width="30px"
                                height="30px"
                                viewBox="0 0 24.00 24.00"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g
                                    stroke="#ffffff"
                                    stroke-width="1.6799999999999997"
                                  >
                                    {" "}
                                    <path d="M16 16V8a1 1 0 00-1-1H5a1 1 0 00-1 1v8a1 1 0 001 1h10a1 1 0 001-1z"></path>{" "}
                                    <path
                                      stroke-linejoin="round"
                                      d="M20 7l-4 3v4l4 3V7z"
                                    ></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            )}
                            {!isWebcamOn ? "Start Camera" : "Stop Camera"}
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                }

                <div className="system-compatible">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill={
                        isCameraRequired
                          ? isWebcamOn && agreeToTermsAndConditions
                          : agreeToTermsAndConditions
                          ? "#00C49A"
                          : "#848585"
                      }
                    />
                    <path
                      d="M12.2419 7.60926V7.99956C12.2414 8.91441 11.9452 9.80457 11.3974 10.5373C10.8496 11.27 10.0797 11.8061 9.20238 12.0655C8.32508 12.3248 7.38743 12.2937 6.52928 11.9767C5.67113 11.6596 4.93845 11.0737 4.44052 10.3062C3.94259 9.53873 3.70608 8.63086 3.76628 7.718C3.82647 6.80513 4.18014 5.93618 4.77453 5.24074C5.36893 4.5453 6.1722 4.06063 7.06455 3.85902C7.95691 3.6574 8.89053 3.74964 9.72617 4.12198"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.242 4.60562L7.99953 8.85229L6.72681 7.57956"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>System Compatible for test</span>
                </div>

                <div className="instructions">
                  <span>Test Instructions</span>
                  <ol>
                    {testData?.instruction?.map((instruction, index) => {
                      return <li>{instruction.instruction}</li>;
                    })}
                  </ol>
                </div>
              </div>
            </div>
            <div className="right-content">
              <div className="empty"></div>
              <div className="ellipse">
                <svg
                  width="423"
                  height="385"
                  viewBox="0 0 423 385"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="63.5"
                    cy="359.5"
                    r="359.5"
                    fill="white"
                    fillOpacity="0.04"
                  />
                </svg>
              </div>
              <div className="ellipse-2">
                <svg
                  width="160"
                  height="281"
                  viewBox="0 0 160 281"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="359.5"
                    cy="-78.5"
                    r="359.5"
                    fill="white"
                    fillOpacity="0.04"
                  />
                </svg>
              </div>

              <div className="big-box">
                <span>
                  {testData?.name.replace(/\b\w/g, (c) => c.toUpperCase())}
                </span>
                <p>
                  {moment(testData?.startDate).format("DD MMM ")}
                  {testData?.endDate
                    ? "- " + moment(testData?.endDate).format(" DD MMM")
                    : "- NA"}
                </p>
                <div className="time">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3278_2106)">
                      <path
                        d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 5V10L13.3333 11.6667"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3278_2106">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>
                    {moment
                      .utc(
                        moment
                          .duration(testData?.totalTiming, "minutes")
                          .asMilliseconds()
                      )
                      .format("HH:mm:ss")}{" "}
                    Hrs
                  </span>
                </div>
              </div>

              <div className="body">
                <div
                  className="policy"
                  onClick={() =>
                    setAgreeToTermsToConditions(!agreeToTermsAndConditions)
                  }
                >
                  <input
                    type="checkbox"
                    checked={agreeToTermsAndConditions}
                    onChange={() =>
                      setAgreeToTermsToConditions(!agreeToTermsAndConditions)
                    }
                  />
                  <span>
                    I agree to the Privacy Policy and Terms of Use of EliteQA.
                  </span>
                </div>
                <div className="button-container">
                  {toEnableButton() ? (
                    <button
                      id="startButton"
                      style={{ border: "none", background: "none" }}
                      onClick={startTestHandler}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="13" cy="13" r="13" fill={"#FF6812"} />
                        <path
                          d="M19.8934 12.3652V12.9994C19.8925 14.4861 19.4111 15.9326 18.521 17.1233C17.6309 18.314 16.3797 19.185 14.9541 19.6065C13.5285 20.028 12.0048 19.9774 10.6103 19.4622C9.21579 18.947 8.02519 17.9949 7.21606 16.7477C6.40692 15.5006 6.0226 14.0253 6.12041 12.5419C6.21823 11.0585 6.79294 9.64646 7.75883 8.51637C8.72472 7.38628 10.03 6.59869 11.4801 6.27106C12.9302 5.94344 14.4473 6.09333 15.8052 6.69839"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.8935 7.48429L12.9996 14.3851L10.9314 12.3169"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Start Test</span>
                    </button>
                  ) : (
                    <button
                      id="startButton"
                      style={{ border: "none", background: "none" }}
                      onClick={catchError}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="13" cy="13" r="13" fill={"#6f6161"} />
                        <path
                          d="M19.8934 12.3652V12.9994C19.8925 14.4861 19.4111 15.9326 18.521 17.1233C17.6309 18.314 16.3797 19.185 14.9541 19.6065C13.5285 20.028 12.0048 19.9774 10.6103 19.4622C9.21579 18.947 8.02519 17.9949 7.21606 16.7477C6.40692 15.5006 6.0226 14.0253 6.12041 12.5419C6.21823 11.0585 6.79294 9.64646 7.75883 8.51637C8.72472 7.38628 10.03 6.59869 11.4801 6.27106C12.9302 5.94344 14.4473 6.09333 15.8052 6.69839"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.8935 7.48429L12.9996 14.3851L10.9314 12.3169"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="start-disabled">Start Test</span>
                    </button>
                  )}
                  {/* {isCameraRequired ? (
                    !isWebcamOn || !agreeToTermsAndConditions
                  ) : !agreeToTermsAndConditions ? (
                    <button
                      id="startButton"
                      style={{ border: "none", background: "none" }}
                      onClick={catchError}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="13" cy="13" r="13" fill={"#6f6161"} />
                        <path
                          d="M19.8934 12.3652V12.9994C19.8925 14.4861 19.4111 15.9326 18.521 17.1233C17.6309 18.314 16.3797 19.185 14.9541 19.6065C13.5285 20.028 12.0048 19.9774 10.6103 19.4622C9.21579 18.947 8.02519 17.9949 7.21606 16.7477C6.40692 15.5006 6.0226 14.0253 6.12041 12.5419C6.21823 11.0585 6.79294 9.64646 7.75883 8.51637C8.72472 7.38628 10.03 6.59869 11.4801 6.27106C12.9302 5.94344 14.4473 6.09333 15.8052 6.69839"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.8935 7.48429L12.9996 14.3851L10.9314 12.3169"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="start-disabled">Start Test</span>
                    </button>
                  ) : (
                    <button
                      id="startButton"
                      style={{ border: "none", background: "none" }}
                      onClick={startTestHandler}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="13" cy="13" r="13" fill={"#FF6812"} />
                        <path
                          d="M19.8934 12.3652V12.9994C19.8925 14.4861 19.4111 15.9326 18.521 17.1233C17.6309 18.314 16.3797 19.185 14.9541 19.6065C13.5285 20.028 12.0048 19.9774 10.6103 19.4622C9.21579 18.947 8.02519 17.9949 7.21606 16.7477C6.40692 15.5006 6.0226 14.0253 6.12041 12.5419C6.21823 11.0585 6.79294 9.64646 7.75883 8.51637C8.72472 7.38628 10.03 6.59869 11.4801 6.27106C12.9302 5.94344 14.4473 6.09333 15.8052 6.69839"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.8935 7.48429L12.9996 14.3851L10.9314 12.3169"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Start Test</span>
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="user-test-1-content">
            <div className="content">
              <div className="nodata">
                <div className="message" style={{ color: "white" }}>
                  {errorMessage}
                </div>
                <div className="controls">
                  <div className="button2">
                    <Link style={{ textDecoration: "none" }} to="/">
                      <div className="btn2">
                        <span>Go Back</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </>
      )}
      {isError && <ErrorPopup errorData={errorData} setError={setError} />}
    </>
  );
}

export default EnterTestPreview;
