import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar2 from "../navbar-2/Navbar2";
import TestLoading from "../test-loading/TestLoading";
import "./TestQuestion.css";
import logo from "../../assets/logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserTest4Sidebar from "../user-test-4-sidebar/UserTest4Sidebar";
import TestEndWarning from "../test-end-warning/TestEndWarning";
import TestContext from "../../context/test/testContext";
import CameraContext from "../../context/camera/CameraContext";
import useVisibilityChange from "../../utils/visibility";
// import ErrorPopup from "../ErrorPopup/common/ErrorPopup";
import APICall from "../../services/api.service";
import moment from "moment";

function SingleQuestionPreview() {
  const params = useParams();
  const questionId = params.questionId;

  const [isMobile, setIsMobile] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState();

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [endTask, setEndTask] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const questionsPerPage = 10;

  const indexOfLastQuestion = currentPage * questionsPerPage;

  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;

  const resetAnswer = () => {
    setSelectedOptions([]);
  };

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  const toggleSelectedAnswer = (optionId) => {
    if (currentQuestion.correctAnswerType === "No") {
      setSelectedOptions([optionId]);
    } else if (currentQuestion.correctAnswerType === "Yes") {
      setSelectedOptions((prevSelectedAnswers) => {
        if (prevSelectedAnswers.includes(optionId)) {
          // Remove the optionId from the selected answers array
          return prevSelectedAnswers.filter((_id) => _id !== optionId);
        } else {
          // Add the optionId to the selected answers array
          return [...prevSelectedAnswers, optionId];
        }
      });
    }
  };

  const getQuestion = async () => {
    const res = await APICall(`/api/getQuestion/${questionId}`);
    console.log(res.data);
    setCurrentQuestion(res.data.data);
  };

  useEffect(() => {
    getQuestion();
  }, [questionId]);

  return (
    <div className="user-test-question-container">
      <>
        {isLoading ? (
          <div className="loader">
            <TestLoading />
          </div>
        ) : (
          <div>
            <Navbar2
              endtask={() => {
                setEndTask(!endTask);
              }}
              currentQuestion={currentQuestion?._id}
            />

            <div className="user-test-4-content">
              {endTask && (
                <TestEndWarning
                  endtask={() => {
                    setEndTask(!endTask);
                  }}
                />
              )}
              {
                // <div className="test-4-sidebar">
                //   <UserTest4Sidebar currentQuestion={currentQuestion} />
                // </div>
              }

              <div className="right-side-content">
                <div className="right-container">
                  <div className="header">
                    <div className="title">
                      <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="25"
                          cy="25"
                          r="25"
                          fill="#00C49A"
                          fillOpacity="0.1"
                        />
                        <path
                          d="M25 35C30.5228 35 35 30.5228 35 25C35 19.4772 30.5228 15 25 15C19.4772 15 15 19.4772 15 25C15 30.5228 19.4772 35 25 35Z"
                          stroke="#00C49A"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22.09 22C22.3251 21.3317 22.7891 20.7681 23.4 20.4091C24.0108 20.0501 24.7289 19.9189 25.4272 20.0387C26.1255 20.1585 26.7588 20.5215 27.2151 21.0635C27.6713 21.6055 27.9211 22.2915 27.92 23C27.92 25 24.92 26 24.92 26"
                          stroke="#00C49A"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M25 30H25.01"
                          stroke="#00C49A"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <div className="group">
                        <span>
                          Q.
                          {1}
                        </span>
                        {isMobile && (
                          <span className="mobile-score">
                            {" "}
                            Max Score:{" "}
                            {currentQuestion?.score
                              ? currentQuestion?.score
                              : 4}
                          </span>
                        )}
                      </div>
                    </div>

                    {isMobile ? (
                      <div className="btn-container">
                        {
                          <Link style={{ textDecoration: "none" }}>
                            <div className="mobile-back-button">
                              <svg
                                width="24"
                                height="21"
                                viewBox="0 0 24 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="12" cy="10.5" r="10" fill="white" />
                                <path
                                  d="M15 5.45926L9 10.1123L15 14.7654"
                                  stroke="#827C7C"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </Link>
                        }

                        {
                          <Link style={{ textDecoration: "none" }}>
                            <div className="mobile-next-button">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="10" cy="10" r="10" fill="white" />
                                <path
                                  d="M8 15.2661L14 10.613L8 5.95996"
                                  stroke="#00C49A"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </Link>
                        }
                      </div>
                    ) : (
                      <div className="score-container">
                        <span>
                          Max Score:{" "}
                          {currentQuestion?.score ? currentQuestion?.score : 4}
                        </span>
                        <span className="score-badge">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0226 19.3959L7.21012 16.6667H3.33512V12.7917L0.543457 10.0001L3.33512 7.20841V3.33341H7.21012L10.0226 0.541748L12.7935 3.33341H16.6685V7.20841L19.4601 10.0001L16.6685 12.7917V16.6667H12.7935L10.0226 19.3959ZM10.0226 17.6459L12.2709 15.4167H15.4185V12.2709L17.6893 10.0001L15.4185 7.72925V4.58341H12.2726L10.0226 2.31258L7.73096 4.58341H4.58512V7.72925L2.31429 10.0001L4.58512 12.2709V15.4167H7.71012L10.0226 17.6459ZM7.56429 13.5417L10.0018 12.0626L12.4393 13.5417L11.7935 10.7709L13.9601 8.89591L11.106 8.66675L10.0018 6.04175L8.89762 8.66675L6.04346 8.89591L8.21012 10.7709L7.56429 13.5417Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="container">
                    <div className="ques-title">
                      <div>
                        <svg
                          width="10"
                          height="19"
                          viewBox="0 0 10 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="10" height="19" rx="2" fill="#FF6812" />
                        </svg>
                      </div>
                      {currentQuestion?.html ? (
                        <div
                          className="sun-editor-editable"
                          dangerouslySetInnerHTML={{
                            __html: currentQuestion?.html,
                          }}
                        />
                      ) : (
                        <div className="ques">{currentQuestion?.question}</div>
                      )}
                    </div>
                    <div className="options-box">
                      {currentQuestion?.correctAnswerType === "No" ? (
                        <>
                          {currentQuestion?.answersObjectArray?.map(
                            (data, index) => {
                              return (
                                <div
                                  className={
                                    selectedOptions.includes(data.optionId)
                                      ? "html-option-box-marked"
                                      : "html-option-box-unmarked"
                                  }
                                  key={index}
                                  onClick={() => {
                                    toggleSelectedAnswer(data.optionId);
                                  }}
                                >
                                  <div className={"option-content"}>
                                    <div>
                                      {selectedOptions.includes(
                                        data.optionId
                                      ) ? (
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="10"
                                            cy="10"
                                            r="10"
                                            fill="#00C49A"
                                          />
                                          <circle
                                            cx="9.9997"
                                            cy="9.99969"
                                            r="3.65385"
                                            fill="white"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          onClick={() => {
                                            toggleSelectedAnswer(data.optionId);
                                          }}
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.0006 1.53813C6.57831 1.53813 3.49303 3.59981 2.1834 6.76162C0.873778 9.92343 1.59766 13.5626 4.01751 15.9826C6.15022 18.1442 9.27762 18.9972 12.2124 18.2178C15.1472 17.4385 17.4394 15.1462 18.2188 12.2116C18.9982 9.27664 18.1452 6.14939 15.9837 4.01673C14.4004 2.42455 12.2459 1.53212 10.0006 1.53813ZM10 0C15.5228 0 20 4.47716 20 10C20 15.5228 15.5229 20 10 20C4.47721 20 4.69501e-05 15.5228 0 10C0 4.47716 4.47721 0 10 0H10Z"
                                            fill="#A8AAAC"
                                          />
                                        </svg>
                                      )}
                                    </div>

                                    {data?.html ? (
                                      <div
                                        className="sun-editor-editable"
                                        dangerouslySetInnerHTML={{
                                          __html: data?.html,
                                        }}
                                      />
                                    ) : (
                                      (data.option === true && <p>True</p>) ||
                                      (data?.option === false && (
                                        <p>False</p>
                                      )) || <p>{data?.option}</p>
                                    )}
                                  </div>
                                  {selectedOptions.includes(data.optionId) ? (
                                    <div className="save">
                                      <span>Saved!</span>
                                    </div>
                                  ) : null}
                                </div>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <>
                          {currentQuestion?.answersObjectArray?.map(
                            (data, index) => {
                              const isChecked = selectedOptions.includes(
                                data.optionId
                              );

                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedOptions.includes(data.optionId)
                                      ? "html-option-box-marked"
                                      : "html-option-box-unmarked"
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSelectedAnswer(data.optionId);
                                  }}
                                >
                                  <div className={"option-content"}>
                                    {isChecked ? (
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="19"
                                          height="19"
                                          rx="3.5"
                                          fill="#00C49A"
                                          stroke="#00C49A"
                                        />
                                        <path
                                          d="M6 10L9 13L14 7"
                                          stroke="white"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="19"
                                          height="19"
                                          rx="3.5"
                                          fill="white"
                                          stroke="#A8AAAC"
                                        />
                                      </svg>
                                    )}

                                    {data?.html ? (
                                      <div
                                        className="sun-editor-editable"
                                        dangerouslySetInnerHTML={{
                                          __html: data?.html,
                                        }}
                                      />
                                    ) : (
                                      (data.option === true && <p>True</p>) ||
                                      (data?.option === false && (
                                        <p>False</p>
                                      )) || <p>{data?.option}</p>
                                    )}
                                  </div>

                                  {isChecked ? (
                                    <div className="save">
                                      <span>Saved!</span>
                                    </div>
                                  ) : null}
                                </div>
                              );
                            }
                          )}
                        </>
                      )}

                      <div className="controls-container">
                        <div
                          className="reset"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            resetAnswer();
                          }}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3278_2262)">
                              <path
                                d="M17.25 3V7.5H12.75"
                                stroke="#978989"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M0.75 15V10.5H5.25"
                                stroke="#978989"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M2.6325 6.75C3.01288 5.67509 3.65935 4.71406 4.5116 3.95657C5.36385 3.19908 6.3941 2.66983 7.50621 2.4182C8.61833 2.16657 9.77607 2.20076 10.8714 2.51758C11.9667 2.83441 12.9639 3.42354 13.77 4.23L17.25 7.5M0.75 10.5L4.23 13.77C5.03606 14.5765 6.03328 15.1656 7.12861 15.4824C8.22393 15.7992 9.38167 15.8334 10.4938 15.5818C11.6059 15.3302 12.6361 14.8009 13.4884 14.0434C14.3407 13.286 14.9871 12.3249 15.3675 11.25"
                                stroke="#978989"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath _id="clip0_3278_2262">
                                <rect width="18" height="18" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>Reset Answer</span>
                        </div>
                        {!isMobile ? (
                          <div className="btn-container">
                            {
                              <Link style={{ textDecoration: "none" }}>
                                <div className="back-button">
                                  <svg
                                    width="24"
                                    height="21"
                                    viewBox="0 0 24 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="12"
                                      cy="10.5"
                                      r="10"
                                      fill="white"
                                    />
                                    <path
                                      d="M15 5.45926L9 10.1123L15 14.7654"
                                      stroke="#827C7C"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span>Back</span>
                                </div>
                              </Link>
                            }

                            {
                              <Link style={{ textDecoration: "none" }}>
                                <div className="next-button">
                                  <span>Next</span>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      fill="white"
                                    />
                                    <path
                                      d="M8 15.2661L14 10.613L8 5.95996"
                                      stroke="#00C49A"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </Link>
                            }
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="footer">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default SingleQuestionPreview;
