import React, { useState, useEffect, useContext } from "react";
import Navbar2 from "../navbar-2/Navbar2";
import TestLoading from "../test-loading/TestLoading";
import "./TestOverview.css";
import logo from "../../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserTest4Sidebar from "../user-test-4-sidebar/UserTest4Sidebar";
import TestEndWarning from "../test-end-warning/TestEndWarning";
import TestContext from "../../context/test/testContext";
import CameraContext from "../../context/camera/CameraContext";
import useVisibilityChange from "../../utils/visibility";
import ErrorPopup from "../ErrorPopup/common/ErrorPopup";

import { enterFullScreen } from "../../utils/screenUtils";
import queryString from "query-string";

function TestOverviewPreview() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [endTask, setEndTask] = useState(false);
  // const [testSummary, setTestSummary] = useState();
  const navigate = useNavigate();
  const {
    testQuestions,
    setTestQuestions,
    testData,
    setTabSwitchCount,
    handleTestSubmit,
    inviteId,
  } = useContext(TestContext);
  const {
    isCameraOn,
    videoRef,
    checkIfCameraExists,
    checkCameraStatus,
    startSnapshotInterval,
  } = useContext(CameraContext);

  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;

  const search = location.search;
  const parsed = queryString.parse(search);
  const currentQuestion = parsed.currentQuestion;

  const [isCameraRequired, setIsCameraRequired] = useState(false);

  const startCamera = () => {
    if (checkIfCameraExists()) {
      checkCameraStatus();
    } else {
      return "Camera not found";
    }
  };

  useVisibilityChange(() => {
    if (document.visibilityState !== "visible") {
      setTabSwitchCount((prevCount) => prevCount + 1);
      testData.proctoringSettings.map((setting) => {
        if (setting.setting === "Logout on leaving a test interface") {
          if (setting.active) {
            navigate("/testsubmitted", { replace: true });
          }
        }
      });
    }
  });

  // Handle click on question
  const openQuestion = (questionId) => {
    navigate(`/preview/testquestion/${questionId}`);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    testData.proctoringSettings.map((setting) => {
      if (setting.setting === "Take Snapshots via webcam every 30 second") {
        if (setting.active) {
          startCamera();
          setIsCameraRequired(true);
        }
      }
    });
  }, [isCameraOn]);

  useEffect(() => {
    testData.proctoringSettings.map((setting) => {
      if (setting.setting === "Turn on fullscreen while test") {
        if (setting.active) {
          enterFullScreen();
        }
      }
    });
  }, []);

  //calculate total pages
  const totalPages = Math.ceil(testQuestions?.length / questionsPerPage);

  //find sections
  const uniqueType = [
    ...new Set(testQuestions?.map((question) => question.type)),
  ];

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  return (
    <div className="user-test-overview-container">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        style={{ position: "fixed", bottom: 20, right: 20, width: "100px" }}
      />
      {isLoading ? (
        <div className="loader">
          <TestLoading />
        </div>
      ) : (
        <div>
          <Navbar2
            endtask={() => {
              setEndTask(!endTask);
            }}
            hideOverview
          />

          <div className="user-test-4-content">
            {endTask && (
              <TestEndWarning
                endtask={() => {
                  setEndTask(!endTask);
                }}
              />
            )}
            {
              <UserTest4Sidebar
                testQuestions={testQuestions}
                openQuestion={openQuestion}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                indexOfFirstQuestion={indexOfFirstQuestion}
                indexOfLastQuestion={indexOfLastQuestion}
                overview
              />
            }

            <div className="right-side-content">
              <div className="right-container">
                <div className="header">
                  <div className="title">
                    <div className="group">
                      <span>Test Overview ({testQuestions?.length})</span>
                    </div>
                    <div className="score-container">
                      <span>Total Score: {testData?.totalScore}</span>
                      <span className="score-badge">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0226 19.3959L7.21012 16.6667H3.33512V12.7917L0.543457 10.0001L3.33512 7.20841V3.33341H7.21012L10.0226 0.541748L12.7935 3.33341H16.6685V7.20841L19.4601 10.0001L16.6685 12.7917V16.6667H12.7935L10.0226 19.3959ZM10.0226 17.6459L12.2709 15.4167H15.4185V12.2709L17.6893 10.0001L15.4185 7.72925V4.58341H12.2726L10.0226 2.31258L7.73096 4.58341H4.58512V7.72925L2.31429 10.0001L4.58512 12.2709V15.4167H7.71012L10.0226 17.6459ZM7.56429 13.5417L10.0018 12.0626L12.4393 13.5417L11.7935 10.7709L13.9601 8.89591L11.106 8.66675L10.0018 6.04175L8.89762 8.66675L6.04346 8.89591L8.21012 10.7709L7.56429 13.5417Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  {
                    <Link
                      style={{ textDecoration: "none" }}
                      to={
                        currentQuestion
                          ? `/preview/testquestion/${currentQuestion}`
                          : "/preview/testquestion"
                      }
                    >
                      <div className="btn-next">
                        <span>{currentQuestion ? "Back" : "Next"}</span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="10" r="10" fill="white" />
                          <path
                            d="M8 15.2661L14 10.613L8 5.95996"
                            stroke="#00C49A"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  }
                </div>
                <div className="overview-container">
                  {uniqueType?.map((type) => {
                    // Filter questions based on the topic
                    const filteredQuestions = testQuestions.filter(
                      (question) => question.type === type
                    );

                    // Calculate the sum of scores for the section
                    const sectionMarks = filteredQuestions.reduce(
                      (sum, question) => sum + question.score,
                      0
                    );
                    return (
                      <div className="topic" key={type}>
                        <div className="section-container">
                          <div className="section-title">
                            <h4>{`${uniqueType} (${filteredQuestions.length})`}</h4>
                          </div>
                          <div className="section-info">
                            Section Marks {sectionMarks}
                          </div>
                        </div>
                        {filteredQuestions.map((question, index) => (
                          <div
                            className="question"
                            key={question._id}
                            onClick={() => openQuestion(question._id)}
                          >
                            <div>
                              <div>{`Q.${index + 1} `}</div>
                              {question?.html ? (
                                <div
                                  className="sun-editor-editable"
                                  dangerouslySetInnerHTML={{
                                    __html: question?.html,
                                  }}
                                />
                              ) : (
                                <div>{question?.question}</div>
                              )}
                            </div>
                            <div className="question-status">
                              <div className="marks">{question.score}</div>
                              <div className="attempt-status">
                                {question.isAttempted ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 200 200"
                                  >
                                    <circle
                                      cx="100"
                                      cy="100"
                                      r="80"
                                      fill="none"
                                      stroke="#00C49A"
                                      strokeWidth="20 "
                                    />
                                    <path
                                      d="M60 110 L90 140 L190 50"
                                      fill="none"
                                      stroke="#00C49A"
                                      strokeWidth="15"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 200 200"
                                  >
                                    <circle
                                      cx="100"
                                      cy="100"
                                      r="80"
                                      fill="none"
                                      stroke="#949393"
                                      strokeWidth="15"
                                    />
                                    <path
                                      d="M60 110 L90 140 L190 50"
                                      fill="none"
                                      stroke="#949393"
                                      strokeWidth="15"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
                <div className="footer">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isCameraRequired && !isCameraOn && (
        <ErrorPopup
          errorData={{
            errorMessage: "Camera got turned off!",
            actions: [
              {
                button: "Start Camera",
                action: startCamera,
              },
            ],
          }}
        ></ErrorPopup>
      )}
    </div>
  );
}

export default TestOverviewPreview;
