import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "suneditor/dist/css/suneditor.min.css";
import TestSummary from "./components/summary/TestSummary";
import UserTest2popup from "./components/user-test-2-popup/UserTest2Popup";
import FillDetails from "./components/details/FillDetails";
import EnterTest from "./components/permissions/EnterTest";
import TestQuestion from "./components/testquestion/TestQuestion";
import TestLoading from "./components/test-loading/TestLoading";
import TestEndWarning from "./components/test-end-warning/TestEndWarning";
import TimeEnded from "./components/time-ended/TimeEnded";
import TestSubmitted from "./components/test-submitted/TestSubmitted";
import UserTest4Sidebar from "./components/user-test-4-sidebar/UserTest4Sidebar";
import TestProvider from "./context/test/TestProvider";
import TestOverview from "./components/overview/TestOverview";
import TestReview from "./components/review/TestReview";
import CameraProvider from "./context/camera/CameraProvider";
import FullscreenMode from "./components/ErrorPopup/fullscreen/FullScreenMode";
import FindTest from "./components/findtest/FindTest";

import EnterTestPreview from "./testpreview/permissions/EnterTestPreview";
import TestOverviewPreview from "./testpreview/overview/TestOverviewPreview";
import TestQuestionPreview from "./testpreview/testquestion/TestQuestionPreview";
import TestReviewPreview from "./testpreview/review/TestReview";
import SingleQuestionPreview from "./questionpreview/questionpreview/SingleQuestionPreview";

function App() {
  return (
    <div className="App">
      <CameraProvider>
        <TestProvider>
          <Routes>
            {/* <Route path="/" exact element={<ScreenRecordingComponent />} /> */}
            <Route path="/" exact element={<FindTest />} />
            <Route path="/details" exact element={<FillDetails />} />
            <Route path="/UserTest2popup" exact element={<UserTest2popup />} />
            <Route path="/permissions" exact element={<EnterTest />} />
            <Route
              path="/overview"
              exact
              element={<FullscreenMode WrappedComponent={TestOverview} />}
            />
            <Route
              path="/test"
              element={<FullscreenMode WrappedComponent={TestQuestion} />}
            />
            <Route
              path="/test/:questionId"
              element={<FullscreenMode WrappedComponent={TestQuestion} />}
            />
            <Route
              path="/review"
              exact
              element={<FullscreenMode WrappedComponent={TestReview} />}
            />
            <Route path="/testsubmitted" exact element={<TestSubmitted />} />
            <Route path="/testloading" exact element={<TestLoading />} />
            <Route path="/testendwarning" exact element={<TestEndWarning />} />
            <Route path="/timeended" exact element={<TimeEnded />} />
            <Route path="/test4popup" exact element={<UserTest4Sidebar />} />

            <Route path="/:testId" element={<TestSummary />} />
            <Route path="/:userId/:testDetails" element={<TestSummary />} />
            <Route
              path="/:userId/graphics/ui/:testDetails"
              element={<TestSummary />}
            />

            {/* Preview Routes */}

            <Route path="/preview" exact element={<EnterTestPreview />} />
            <Route
              path="/:userId/:testDetails/preview"
              exact
              element={<EnterTestPreview />}
            />

            <Route
              path="/preview/testoverview"
              exact
              element={
                <FullscreenMode WrappedComponent={TestOverviewPreview} />
              }
            />
            <Route
              path="/preview/testquestion"
              exact
              element={
                <FullscreenMode WrappedComponent={TestQuestionPreview} />
              }
            />
            <Route
              path="/preview/question/:questionId"
              exact
              element={
                <FullscreenMode WrappedComponent={SingleQuestionPreview} />
              }
            />
            <Route
              path="/preview/testquestion/:questionId"
              exact
              element={
                <FullscreenMode WrappedComponent={TestQuestionPreview} />
              }
            />
            <Route
              path="/preview/testreview"
              exact
              element={<FullscreenMode WrappedComponent={TestReviewPreview} />}
            />
          </Routes>
        </TestProvider>
      </CameraProvider>

      <ToastContainer position="top-right" />
    </div>
  );
}

export default App;
