import React, { useContext, useState } from "react";
import Navbar from "../../navbar/Navbar";
import "./ErrorPopup.css";
import CameraContext from "../../../context/camera/CameraContext";

function ErrorPopup(props) {
  const { errorData, setError } = props;
  return (
    <>
      {
        <div className="user-test-2-popup-container">
          <div className="user-test-2">
            <div className="user-test-2-content-popup">
              <div className="popup-header">
                <div className="error-popup-title">
                  <div className="title">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#fb8c2a"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="8" x2="12" y2="12" />
                      <line x1="12" y1="16" x2="12" y2="16" />
                    </svg>
                  </div>
                  <span className="message">
                    {errorData.errorMessage}
                    {errorData.help && (
                      <a href={errorData.help} target="blank">
                        need help?
                      </a>
                    )}
                  </span>
                </div>
                <div className="header-border"></div>
              </div>

              <div className="body">
                {errorData?.actions?.map((action, index) => {
                  return (
                    <div key={index} className="camera" onClick={action.action}>
                      <span>{action.button}</span>
                    </div>
                  );
                })}
                {!errorData.actions && (
                  <div className="camera" onClick={() => setError(false)}>
                    <span>Close</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default ErrorPopup;
