import { useEffect } from "react";
function useVisibilityChange(onVisibilityChange) {
  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [onVisibilityChange]);
}
export default useVisibilityChange;
