import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./TestSummary.css";
import Navbar from "../navbar/Navbar";
import logo from "../../assets/logo.png";
import UserTest2Popup from "../user-test-2-popup/UserTest2Popup";
import HamburgerPopup from "../hamburger-popup/HamburgerPopup";
import TestContext from "../../context/test/testContext";
import APICall from "../../services/api.service";
import TestLoading from "../test-loading/TestLoading";
import moment from "moment";

function TestSummary() {
  const [toggleHelp, settoggleHelp] = useState(false);
  const [toggleHamburger, settoggleHamburger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { testData, testSummary, setTestData, setTestSummary } =
    useContext(TestContext);
  const [errorMessage, setErrorMessage] = useState();

  let testId;
  const params = useParams();
  testId = params.testId;
  const { testDetails } = useParams();
  if (testDetails) {
    testId = testDetails?.split("-").pop();
  }

  const getTestData = async (testID) => {
    try {
      const response = await APICall(`/api/exam/test/${testID}`);
      if (response.data) {
        if (
          response.data.data.status === "published" &&
          response.data.data.isActive
        ) {
          moment(response.data.data.startDate).format("YYYY-MM-DD") <=
          moment(new Date().toISOString()).format("YYYY-MM-DD")
            ? response.data.data.hasOwnProperty("endDate")
              ? moment(response.data.data.endDate).format("YYYY-MM-DD") >=
                moment(new Date().toISOString()).format("YYYY-MM-DD")
                ? setTestData(response.data.data)
                : setErrorMessage(
                    "Sorry, the test is no longer accessible as the end date has passed."
                  )
              : setTestData(response.data.data)
            : setErrorMessage(
                "Sorry, You can't access test before the schedule date."
              );
        } else {
          setErrorMessage("Sorry, the test is no longer accessible");
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTestSummary = async (testId) => {
    try {
      const response = await APICall(`/api/exam/test/${testId}/summary`);
      if (response.data) {
        setTestSummary(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTestData(testId);
    getTestSummary(testId);
  }, []);

  return (
    <div className="user-test-1-container">
      {isLoading ? (
        <div className="loader">
          <TestLoading />
        </div>
      ) : (
        <>
          {testData ? (
            <>
              <Navbar
                settoggleHelp={() => settoggleHelp(!toggleHelp)}
                settoggleHamburger={() => settoggleHamburger(!toggleHamburger)}
                active={"test1"}
              />
              {toggleHamburger && (
                <HamburgerPopup
                  settoggleHamburger={() =>
                    settoggleHamburger(!toggleHamburger)
                  }
                  active={"test1"}
                />
              )}
              <div
                className="user-test-1-content"
                style={
                  toggleHelp ? { overflow: "hidden" } : { overflow: "auto" }
                }
              >
                {toggleHelp && (
                  <UserTest2Popup
                    settoggleHelp={() => settoggleHelp(!toggleHelp)}
                  />
                )}
                <div className="content">
                  <div className="header">
                    <div className="title">
                      <span>
                        {testData?.name.replace(/\b\w/g, (c) =>
                          c.toUpperCase()
                        )}
                      </span>
                    </div>
                    <div className="sub-title">
                      <div className="client">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          viewBox="0 0 32 32"
                          enable-background="new 0 0 32 32"
                          width="12px"
                          height="12px"
                          fill="#ffffff"
                          stroke="#ffffff"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <polygon
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              points="5,4 5,28 14,28 14,24 18,24 18,28 27,28 27,4 "
                            ></polygon>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="14"
                              y1="20"
                              x2="18"
                              y2="20"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="14"
                              y1="16"
                              x2="18"
                              y2="16"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="20"
                              y1="24"
                              x2="24"
                              y2="24"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="20"
                              y1="20"
                              x2="24"
                              y2="20"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="20"
                              y1="16"
                              x2="24"
                              y2="16"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="8"
                              y1="24"
                              x2="12"
                              y2="24"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="8"
                              y1="20"
                              x2="12"
                              y2="20"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="8"
                              y1="16"
                              x2="12"
                              y2="16"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="14"
                              y1="12"
                              x2="18"
                              y2="12"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="20"
                              y1="12"
                              x2="24"
                              y2="12"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="8"
                              y1="12"
                              x2="12"
                              y2="12"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="14"
                              y1="8"
                              x2="18"
                              y2="8"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="20"
                              y1="8"
                              x2="24"
                              y2="8"
                            ></line>
                            <line
                              fill="none"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-miterlimit="10"
                              x1="8"
                              y1="8"
                              x2="12"
                              y2="8"
                            ></line>
                          </g>
                        </svg>
                        <span>{testData?.createdBy?.client?.companyName}</span>
                      </div>

                      <div className="test-duration">
                        <svg
                          width="12px"
                          height="12px"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M12 7V12H15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                              stroke="#ffffff"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </svg>
                        <span>
                          Duration:{" "}
                          {moment
                            .utc(
                              moment
                                .duration(testData?.totalTiming, "minutes")
                                .asMilliseconds()
                            )
                            .format("HH:mm")}{" "}
                          Hours
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="user-test-1-body">
                    <div className="body-left">
                      <span> Test description</span>
                      <p>{testData?.description}</p>
                    </div>
                    <div className="body-right">
                      <div className="question">
                        <span>
                          Total Questions ({testData?.totalNoOfQuestions})
                        </span>
                        <div className="container">
                          {testSummary?.questionTypes?.map((type) => {
                            return (
                              <div className={type.type.toLowerCase()}>
                                <span>
                                  {type.count} {type.type}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="instruction">
                        <span>General Instructions</span>
                        <p>
                          <ul>
                            <li>
                              Test duration:{" "}
                              {moment
                                .utc(
                                  moment
                                    .duration(testData?.totalTiming, "minutes")
                                    .asMilliseconds()
                                )
                                .format("HH:mm")}{" "}
                              hrs
                            </li>
                            <li>Ensure that your email ID is correct.</li>
                            <li>
                              Click Submit after you answer each question.
                            </li>
                            <li>Click Help in case of support</li>
                            <li>Check questions properly</li>
                          </ul>
                        </p>
                      </div>

                      <div className="imp">
                        <p>
                          <span>IMP</span>: Try EliteQA’s Practice Test{" "}
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3278_3062)">
                              <path
                                d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                stroke="#00C49A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 4V6"
                                stroke="#00C49A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 8H6.00583"
                                stroke="#00C49A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3278_3062">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button">
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/details?testId=${testData._id}`}
                  >
                    <div className="btn">
                      <span>Next </span>
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="white" />
                        <path
                          d="M8 15.2658L14 10.6128L8 5.9597"
                          stroke="#00C49A"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>

                <div className="footer">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="user-test-1-content">
                <div className="content">
                  <div className="nodata">
                    <div className="message" style={{ color: "white" }}>
                      {errorMessage}
                    </div>
                    <div className="controls">
                      <div className="button2">
                        <Link style={{ textDecoration: "none" }} to="/">
                          <div className="btn2">
                            <span>Go Back</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="footer">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default TestSummary;
