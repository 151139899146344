import React, { useState, useRef } from "react";
import CameraContext from "./CameraContext";
import APICall from "../../services/api.service";
import axios from "axios";
const base64 = require("base64-js");

const CameraProvider = (props) => {
  const [testId, setTestId] = useState();
  const [inviteId, setInviteId2] = useState();
  const [isCameraOn, setIsCameraOn] = useState(false);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const intervalRef = useRef(null);

  //Screen Variables
  const [isScreenRecordingOn, setIsScreenRecordingOn] = useState(false);
  const screenVideoRef = useRef(null);
  const screenStreamRef = useRef(null);

  // Camera Methods
  const checkIfCameraExists = async () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };

  const checkCameraStatus = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
      }
      const videoTracks = stream.getVideoTracks();
      const cameraOn =
        videoTracks.length > 0 &&
        videoTracks.every((track) => track.readyState === "live");

      setIsCameraOn(cameraOn);

      if (cameraOn) {
        startCameraListener();
      }
    } catch (error) {
      setIsCameraOn(false);
    }
  };

  const stopCamera = () => {
    window.location.reload();
  };

  const startCameraListener = () => {
    if (streamRef.current) {
      const videoTracks = streamRef.current.getVideoTracks();
      if (videoTracks.length > 0) {
        const videoTrack = videoTracks[0];

        videoTrack.onended = () => {
          setIsCameraOn(false);
        };
      }
    }
  };

  const takeSnapshot = async () => {
    if (isCameraOn && videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const snapshotData = canvas.toDataURL("image/jpeg");
      // Remove the data type prefix from the Base64 data
      const base64Data = snapshotData.replace(
        /^data:image\/(png|jpeg|jpg);base64,/,
        ""
      );

      // Convert the Base64 data to image bytes
      // const imageBytes = Buffer.from(base64Data, "base64");
      const imageBytes = base64.toByteArray(base64Data);
      try {
        const response = await APICall(
          `/api/exam/test/${testId}/S3Url?inviteId=${inviteId}`
        );

        if (response) {
          try {
            // const response2 = await axios.put(
            //   response.data.uploadURL,
            //   snapshotData
            // );
            await axios.request({
              method: "PUT",
              headers: {
                "Content-Type": "image/jpeg",
              },
              url: response.data.uploadURL,
              data: imageBytes,
            });
            // console.log(response2);
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error.data);
      }
    }
  };

  const startSnapshotInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      takeSnapshot();
    }, 30000); // Take snapshot every 30 seconds
  };

  //Screen Methods
  const startScreenRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      if (screenVideoRef.current) {
        screenVideoRef.current.srcObject = stream;
        screenStreamRef.current = stream;
      }
      const videoTracks = stream.getVideoTracks();
      const ScreenOn =
        videoTracks.length > 0 &&
        videoTracks.every((track) => track.readyState === "live");

      setIsScreenRecordingOn(ScreenOn);

      if (ScreenOn) {
        startScreenListener();
      }
    } catch (error) {
      setIsScreenRecordingOn(false);
    }
  };

  const stopScreen = () => {
    if (screenStreamRef.current) {
      screenStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (screenVideoRef.current) {
      screenVideoRef.current.srcObject = null;
    }
    setIsScreenRecordingOn(false);
  };

  const startScreenListener = () => {
    if (screenStreamRef.current) {
      const videoTracks = screenStreamRef.current.getVideoTracks();
      if (videoTracks.length > 0) {
        const videoTrack = videoTracks[0];
        videoTrack.onended = () => {
          setIsScreenRecordingOn(false);
          startScreenRecording();
        };
      }
    }
  };

  //take snapshot from screen video if user switch to another tab
  const takeScreenSnapshot = () => {
    if (isScreenRecordingOn && screenVideoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = 320;
      canvas.height = 240;
      const context = canvas.getContext("2d");
      context.drawImage(
        screenVideoRef.current,
        0,
        0,
        canvas.width,
        canvas.height
      );
      let snapshotData = canvas.toDataURL("image/jpeg");
      console.log(snapshotData);
      uploadSnapshot(snapshotData);
      // return snapshotData;
    }
  };

  const uploadSnapshot = async (snapshot) => {
    // Remove the data type prefix from the Base64 data
    const base64Data = snapshot.replace(
      /^data:image\/(png|jpeg|jpg);base64,/,
      ""
    );

    // Convert the Base64 data to image bytes
    // const imageBytes = Buffer.from(base64Data, "base64");
    const imageBytes = base64.toByteArray(base64Data);
    try {
      const response = await APICall(
        `/api/exam/test/${testId}/S3Url?inviteId=${inviteId}&snaphotType=screen`
      );

      if (response) {
        try {
          await axios.request({
            method: "PUT",
            headers: {
              "Content-Type": "image/jpeg",
            },
            url: response.data.uploadURL,
            data: imageBytes,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // document.addEventListener("visibilitychange", () => {
  //   if (document.visibilityState !== "visible") {
  //     const snapshot = takeScreenSnapshot();
  //     console.log(snapshot);
  //     if (snapshot) {
  //       if (testId && inviteId) uploadSnapshot(snapshot);
  //     }
  //   }
  // });

  const contextValue = {
    isCameraOn,
    videoRef,
    streamRef,
    startSnapshotInterval,
    checkCameraStatus,
    setIsCameraOn,
    checkIfCameraExists,
    takeSnapshot,
    setInviteId2,
    setTestId,
    stopCamera,
    startScreenRecording,
    stopScreen,
    isScreenRecordingOn,
    setIsScreenRecordingOn,
    takeScreenSnapshot,
  };

  return (
    <CameraContext.Provider value={contextValue}>
      {props.children}

      {screenVideoRef && (
        <video
          ref={screenVideoRef}
          autoPlay
          playsInline
          style={{ position: "fixed", bottom: 150, right: 20, width: "200px" }}
        />
      )}
    </CameraContext.Provider>
  );
};

export default CameraProvider;
